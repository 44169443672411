export const categoryData = [
        "Acting",
        "Baking",
        "Cooking",
        "Crocheting",
        "Drawing",
        "Flower Arrangement",
        "Gardening",
        "Glass Blowing",
        "Metal Shaping",
        "Music",
        "Magic",
        "Photography",
        "Pottery",
        "Racing",
        "Sculpturing",
        "Sewing",
        "Swimming"
]
