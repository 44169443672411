import React from 'react';
import { Avatar } from "antd";
import { UserOutlined } from '@ant-design/icons';

const UserAvatar = () =>{
    return(
        <Avatar style={{backgroundColor: "#800080"}} icon={<UserOutlined/>}/>
    );
}
export default UserAvatar;
