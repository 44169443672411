import { APP_PREFIX_PATH } from '../../../../configs/AppConfig';
import { SessionContext } from '../../../../context/sessionContext';
import React,{createContext, useState, useContext} from 'react';
import { useHistory } from 'react-router-dom';
import { SaveOnboardingData } from '../../api/OnboardingService';

export const OnboardingState = createContext({
    date_of_birth : '',
    interests : [],
    step : 0,
    name : '',
    previous : () => {},
    loading : false,
    first_name : "",
    last_name : "",
    email : "",
    bio : ""
});

const OnboardingContext = ({...props}) => {
    const {children} = props;
    const [step, setStep] = useState(0);
    const history = useHistory();
    const cookieValue = useContext(SessionContext);
    const [onboardingInfo, setOnboardingInfo] = useState({
        date_of_birth : '',
        interests : [],
        step : 0,
        user_type : "instructor",
        first_name : "",
        last_name : "",
        email : "",
        day : 0,
        month : 0,
        year : 0
    });

    const saveDateBirth = (value) => {
        setOnboardingInfo({ ...onboardingInfo, date_of_birth:value });
    };

    const saveInterest = value => {
        let interest_val = value ;
        let interest_index = onboardingInfo.interests.indexOf(interest_val);
        if(interest_index > -1){
            setOnboardingInfo(
                {...onboardingInfo, interests:onboardingInfo.interests.filter(x => x !== interest_val)}
            );
        }else{
            setOnboardingInfo(
                {...onboardingInfo, interests:onboardingInfo.interests.concat(interest_val)}
            );
        }

    };

    const forward = (step) =>{
        setStep(step);
    };

    const previous = () => {
        setStep(step - 1);
    };
    //refactor this line to only be the context data neeeded to complete onboarding
    const saveOnboarding = () =>{
        SaveOnboardingData({
            date_of_birth : `${onboardingInfo.year}-${onboardingInfo.month}-${onboardingInfo.day}`,
            interests : onboardingInfo.interests,
            is_onboarded : true
        },cookieValue.token).then(
            ()=>history.push(`${APP_PREFIX_PATH}/`)
        ).catch(
            err=>console.error("Error occurred saving onboarding details", err)
        )
    };

    const setBirthDay = (type, value) => {
        setOnboardingInfo({
            ...onboardingInfo, [ type ] : value
        })
    };
    return(
        <OnboardingState.Provider 
            value={{
                onboardingInfo: onboardingInfo, 
                saveDateBirth: saveDateBirth,
                saveInterest : saveInterest,
                setOnboardingInfo : setOnboardingInfo,
                forward : forward,
                previous : previous,
                step : step,
                isLoading : false,
                saveOnboarding : saveOnboarding,
                setBirthDay : setBirthDay
            }}
        >
            {
                children
            }
        </OnboardingState.Provider>
    );
};
export default OnboardingContext;
