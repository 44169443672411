// Main Page Images
const in_person_class = 'https://sqiletassets.nyc3.cdn.digitaloceanspaces.com/in_person_class_type.jpg';
const online_class = 'https://sqiletassets.nyc3.cdn.digitaloceanspaces.com/online_class_type.jpg';
const hybrid_class = 'https://sqiletassets.nyc3.cdn.digitaloceanspaces.com/class_type_hybrid.jpg';
const businessTools = 'https://sqiletassets.nyc3.cdn.digitaloceanspaces.com/businessTools.svg';
const ladyCreator = 'https://sqiletassets.nyc3.cdn.digitaloceanspaces.com/mainVectorAvatar.svg';
const sqiletLogo = 'https://sqiletassets.nyc3.cdn.digitaloceanspaces.com/sqiletLogo.svg';
const reachAudience = 'https://sqiletassets.nyc3.cdn.digitaloceanspaces.com/reachAudience.svg';
const startup = 'https://sqiletassets.nyc3.cdn.digitaloceanspaces.com/startup.svg';

export {
    ladyCreator,
    businessTools,
    reachAudience,
    startup,
    sqiletLogo,
    in_person_class,
    online_class,
    hybrid_class
}
