import React from 'react';
import OnboardingContext, { OnboardingState } from './context';
import DateOfBirth from './DateOfBirth';
import Interests from './Interests';

const OnboardingPage = () => {
    const RenderPages = (page, goBack) => {
        switch (page) {
            case 0:
                return <DateOfBirth/>
            case 1:
                return <Interests />
            default:
                break;
        }
    }
    return(
        <OnboardingContext>
            <OnboardingState.Consumer>
                {value => value.isLoading 
                ? "<Loading/>" : 
                    <div className="container d-flex flex-column justify-content-center px-md-4 pb-md-4 pt-md-1" style={{height: "100vh"}}>
                        {
                            RenderPages(value.step, value.goBack)
                        }
                    </div>
                }
            </OnboardingState.Consumer>
        </OnboardingContext>
    );
};
export default OnboardingPage;
