import React from "react";
import { Switch, Route } from "react-router-dom";
import { LANDING_PREFIX_PATH } from '../../configs/AppConfig';
import Main from "./components/Main";
import Home from "./components/Home";
import PrivacyPage from "./components/legal/privacypage";
import TermsOfService from "./components/legal/termsofservice";

export const LandingViews = () => {
  return (
      <Switch>
        <Route path={`${LANDING_PREFIX_PATH}/home`} component={Home} />
        <Route path={`${LANDING_PREFIX_PATH}/main`} component={Main} />
        <Route path={`${LANDING_PREFIX_PATH}/privacy`} component={PrivacyPage} />
        <Route path={`${LANDING_PREFIX_PATH}/terms`} component={TermsOfService} />
      </Switch>
  )
}
export default LandingViews;
