const _baseURL = process.env.REACT_APP_API_URL;

export const post = async(endpoint, params, headers)=>{
    const res = await fetch(_baseURL + endpoint, {
        method: "POST",
        headers: headers,
        body : JSON.stringify( params )
    });

    if (!res.ok) {
        let message = await res.json()
        throw new Error(message.detail)
    }
    
    if (res.status !== 204){
        return res.json()
    }
    
    return 'Internal Error';
};

export const get = async( endpoint, headers )=>{
    const res = await fetch(_baseURL + endpoint, {
        method: "GET",
        headers: headers
    });

    if (!res.ok){ 
        let message = await res.json()
        throw new Error(message.detail)
    }
    
    if (res.status !== 204) 
        return res.json();
    
    return 'Internal Error';
};

export const put = async( endpoint,  params, headers )=>{
    const res = await fetch(_baseURL + endpoint, {
        method: "PUT",
        headers: headers,
        body : JSON.stringify(params)
    });

    if (!res.ok){
        let message = await res.json()
        throw new Error(message.detail)
    }
    
    if (res.status !== 204) 
        return res.json();
    
    return 'Internal Error';
};

export const apiDelete = async(endpoint, headers)=>{
    const res = await fetch(_baseURL + endpoint, {
        method: "PATCH",
        headers: headers,
    });

    if (!res.ok){
        let message = await res.json()
        throw new Error(message.detail)
    }
    
    if (res.status !== 204) 
        return res.json();
    
    return 'Internal Error';
};

export const patch = async( endpoint, params, headers )=> {
    const res = await fetch(_baseURL + endpoint, {
        method: "PATCH",
        headers: headers,
        body : JSON.stringify(params)
    });

    if (!res.ok){
        let message = await res.json()
        throw new Error(message.detail)
    }
    
    if (res.status !== 204)
    {
        return res.json();
    }
    return 'Internal Error';
};
export const upload = async(endpoint, params, headers)=>{
    const res = await fetch(_baseURL + endpoint, {
        method: "POST",
        headers: headers,
        body : params
    });

    if (!res.ok) {
        let message = await res.json()
        throw new Error(message.detail)
    }
    
    if (res.status !== 204){
        return res.json()
    }
    
    return 'Internal Error';
};
