import { GetUser } from "../../views/app-views/api/UserService";
import { GetCourses } from "../../views/app-views/api/CoursesService";
import { getAccount, getAccountBalance } from "../../views/app-views/api/BillingService";
import { GetUserProgress } from "../../views/app-views/api/UserService";

export function getUserProfile(token){
    return GetUser(token).then(
        (result) => result
    ).catch(err=>console.error(err));
};

export function getUserProgressReport(token){
    return GetUserProgress(token).then(
        (data)=>data
    ).catch(
        (err)=>console.error(err)
    )
};

export function getInstructorCourses(status, token){
    return GetCourses(status, token).then(
        (result) => result
    ).catch(err => console.error(err))
};

export function getBillingAccountDetails(token){
    return getAccount(token).then(
        (result) => result
    ).catch(err => console.error(err))
};

export function getUserAccountBalance(token){
    return getAccountBalance(token).then(
        (res) => res
    ).catch(err => console.error(err))
};


export default {getBillingAccountDetails, getUserProfile, getUserProgressReport, getInstructorCourses, getUserAccountBalance}
