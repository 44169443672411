import { format, formatISO } from 'date-fns'

export const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12"
]

const lastDayOfMont = (month) =>{
    var d = new Date( new Date().getFullYear() , parseInt(month) , 0);
    return d.getDate()
}

export const getDaysOfMoth = ( month ) =>{
    let days = []
    for (let day = 0; day < lastDayOfMont(month); day++) {
        days.push(day + 1)
    }
    return days
}

export const getYears = () =>{
    let years = []
    for (let year = 1940; year <= new Date().getFullYear(); year++) {
        years.push(year)
    }
    return years
}

export const toUTC = (date) =>{
    return formatISO(new Date(date));
}

export const toTime = time =>{
    return format(new Date(time),'HH:MM');
}

/**
 * 
 * @param {String} date send a string as date for example: 1991-09-12
 * @param {String} format type of format that you want to be returned for example: YYYY-MM-DD
 * @returns a string date formated
 */
export const toCustomFormat = (date, format_string) =>{
    return format(new Date(date),format_string);
}