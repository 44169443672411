import React from 'react';
import { Link } from 'react-router-dom';
import { LeftCircleOutlined } from '@ant-design/icons';

const BackToMain = () => {
  return (
    <Link to="/landing/main">
      <LeftCircleOutlined className="mr-1" />
      Back To Homepage
    </Link>
  )
}
export default BackToMain;
