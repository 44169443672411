import React,{ useContext, useEffect, useState } from "react";
import { Button, Row, Col } from "antd";
import { OnboardingState } from "./context";
import {get} from "../../../../services/ApiService";
// Components
import CustomCategories from './CustomCategories';
// Icons
import { LeftCircleOutlined } from '@ant-design/icons';

const Interests = () => {
    const { saveInterest, onboardingInfo, saveOnboarding, previous } = useContext(OnboardingState);
    const [ next, setNext  ] = useState(false);
    const [ interests, setInterests  ] = useState([]);
    const getInterestList = async () =>{
        let data = await get('/users/v1/interests')
        setInterests(data)
    }

    const validateType = ( interest ) =>{
        let exists = onboardingInfo.interests.findIndex( x => x === interest)
        return exists >= 0
    }
    
    useEffect(()=>{
        setNext( onboardingInfo.interests.length > 0 )
    },[onboardingInfo.interests])

    useEffect(()=>{
        getInterestList()
    },[])



    return (
    <Row align="middle">
            <Col align="middle" justify="center" md={24}>
                <Button type="default" className="mb-5" onClick={ () => previous() }>
                    <LeftCircleOutlined />
                    Go back
                </Button>
                <h1>Choose some categories</h1>
                <span>Select 1 or more categories you are an expert in</span>
                <section data-cy="interests-section" className="mt-5">
                    {interests.map(item => (
                        <Button
                            type={ validateType(item) ? 'primary' : 'dashed' }
                            className="mt-2 ml-2"
                            key={item}
                            value={item}
                            onClick={()=> { saveInterest(item) } }>
                            {item}
                        </Button>
                    ))}
                    <CustomCategories interests={interests} saveInterest={saveInterest} />
                </section>
                <section className="form-group mt-5">
                    <Button shape="round" type="primary" disabled={ !next } onClick={() => saveOnboarding() }>Next</Button>
                </section>
            </Col>
        </Row>
    );
};

export default Interests;
