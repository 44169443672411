import React, { useState } from 'react'
import { Card, Row, Col, Form, Input, Button, message } from "antd";
import { LockOutlined } from '@ant-design/icons';
import { passwordReset } from '../services/AuthService';
import AlertMessage,{ AlertHook } from '../../../../components/util-components/AlertMessages';
import { useParams } from 'react-router-dom';
import BackToMain from '../../../../views/auth-views/components/BackToMain';

const ResetPassword = () => {
    const { email, token } = useParams();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
    const [ alertProps, setAlertProps ] = AlertHook();

	const onSend =  async (values) => {
		try {
			setLoading(true)
			if( validatePassword(values) ){
                await passwordReset(email, token, values.password)
                message.success('Password updated successfully, you will be redirected to login');
                setLoading(false)
                setTimeout(() => {
                    window.location.href = '/auth/login'
                }, 2000);
            }else{
                setAlertProps({
                    alertType : 'warning',
                    message : "Your password doesn't match, please verify",
                    show : true
                })
                setLoading(false)
            }
		} catch (error) {
            console.log(error)
			setLoading(false)
		}
    }
    const validatePassword = ( values ) =>{
        if(values.password === values.confirm_password){
            return true
        }else{
            return false
        }
    }
	return (
		<div className="h-100 mt-5">
			<div className="container d-flex flex-column justify-content-center h-100">
				<Row justify="center">
					<Col xs={20} sm={20} md={20} lg={9}>
						<Card>
							<div className="my-2">
								<div className="text-center">
									<img className="img-fluid" src="/img/sqilet_3x.png" alt="" />
									<h3 className="mt-3 font-weight-bold">Reset your password</h3>
									<p className="mb-4">Enter your new password</p>
                                    <AlertMessage { ...alertProps }  />
								</div>
								<Row justify="center">
									<Col xs={24} sm={24} md={20} lg={20}>
										<Form form={form} layout="vertical" name="forget-password" onFinish={onSend}>
											<Form.Item 
												name="password"
                                                rules={[
                                                    { 
                                                        required: true,
                                                        message: 'Please type your password'
                                                    },
                                                ]}
                                                >
												<Input.Password placeholder="Password" prefix={<LockOutlined className="text-primary" />}/>
											</Form.Item>
											<Form.Item 
												name="confirm_password"
                                                rules={[
                                                    { 
                                                        required: true,
                                                        message: 'Please confirm your password'
                                                    },
                                                ]}>
												<Input.Password placeholder="Confirm Password" prefix={<LockOutlined className="text-primary" />}/>
											</Form.Item>
											<Form.Item>
												<Button loading={loading} type="primary" htmlType="submit" block>{loading? 'Sending' : 'Send'}</Button>
											</Form.Item>
											<BackToMain/>
										</Form>
									</Col>
								</Row>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default ResetPassword

