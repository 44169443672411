import React from 'react';
import { in_person_class, online_class, hybrid_class} from '../../../constants/Images';
import { Button, Row, Col, Layout } from "antd";
import BootstrapNavbar from './NewNavbar';
import FooterNav from './Footer';
import { categoryData } from '../../../constants/CategoryConstant';
import { Mixpanel } from '../../../Mixpanel';

const Main = () => {
    Mixpanel.track("homepage visit");

    const clickToRoute = () =>{
        Mixpanel.track("click view instructor page button");
        window.location.href = `/landing/home`;
    }

    return (
        <>
            <BootstrapNavbar/>
            <Layout.Content>
                {/* Hero Section */}
                <div className="marketing-section hero">
                    <div className="marketing-section-content">
                        <h1>
                            Explore Creative Classes
                        </h1>
                        <p className="font-size-md mb-4">
                            <i>Taught by experienced Instructors and Creators</i>
                        </p>
                        <Button shape="round" type="primary" size='large' onClick={clickToRoute}>
                            Becoming An Instructor?
                        </Button>
                    </div>
                </div>
                {/* End Hero Section */}
                {/* Categories Section */}
                <div className="categories">
                    <div>
                        <h2 className="categories-title">Access a growing category of classes.</h2>
                    </div>
                    <Row className="mt-4 mb-4">
                        {categoryData.map(value => (
                            <Button
                                className="mt-4 ml-4"
                                key={value}
                                value={value}
                                shape='round'
                                type='default'
                                >
                                {value}
                            </Button>
                        ))}
                    </Row>
                </div>
                {/* End Categories Section */}
                {/* Class Types Section */}
                <div className="class-types">
                    <div className="container">
                        <h2 className="class-types__title">
                            Tailor all classes to fit you
                        </h2>
                        <Row className="class-types__option-container">
                            <Col md={16} className="class-types__descriptions">
                                <h3 className="class-types__option">
                                    In Person
                                </h3>
                                <p className="class-types__text">
                                    Enjoy a safe and inclusive in-person classes offered by our great instructors designed to fit your exact learning needs, learn with a group or one-on-one.
                                </p>
                            </Col>
                            <Col md={8} xs={0} sm={0}>
                                <img className="img-fluid class-types-img" src={in_person_class} alt="In person class"/>
                            </Col>
                        </Row>

                        <Row className="class-types__option-container">
                            <Col md={8} xs={0} sm={0}>
                                <img className="img-fluid class-types-img" src={online_class} alt="Online class"/>
                            </Col>
                            <Col md={16} className="class-types__descriptions">
                                <h3 className="class-types__option">
                                    Online
                                </h3>
                                <p className="class-types__text">
                                    Our online classes are tailored perfectly for those far and near, while still ensuring it is fun and engaging as you will come to expect.
                                </p>
                            </Col>
                        </Row>

                        <Row className="class-types__option-container">
                            <Col md={16} className="class-types__descriptions">
                                <h3 className="class-types__option">
                                    Hybrid
                                </h3>
                                <p className="class-types__text">
                                    For days that you can not be physically present, we have classes that offer a hybrid attendance without sacrificing your enjoyment. 
                                </p>
                            </Col>
                            <Col md={8} xs={0} sm={0}>
                                <img className="img-fluid class-types-img" src={hybrid_class} alt="Hybrid class"/>
                            </Col>
                        </Row>
                    </div>
                </div>
                {/* End Class Types Section */}
                {/* Marketing Section */}
                <div className="pricing-section">
                    <div className="container pricing-content">
                        <h2>No subscriptions, No monthly payments.</h2>
                        <span>Flexible Scheduling and Cancellations Anytime.</span>
                        <h1>$0</h1>                        
                        {/*Download app button goes here when we it is ready */}
                        <span><i>Some cancellations may result in a late charge, please check cancellation policies on your classes</i></span>
                    </div>
                </div>
                {/* End of Marketing section*/}
            </Layout.Content>
            <FooterNav/>
        </>
    )
}
export default Main;
