import { 
  ClockCircleFilled,
  DollarCircleFilled,
  HddFilled,
  DashboardFilled
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from './AppConfig';

const dashBoardNavTree = [
  {
    key: 'Dashboard',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'Dashboard',
    icon: DashboardFilled,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'billing',
    path: `${APP_PREFIX_PATH}/billing`,
    title: 'Billing Details',
    icon: DollarCircleFilled,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'schedules',
    path: `${APP_PREFIX_PATH}/schedules`,
    title: 'Manage Schedules',
    icon: ClockCircleFilled,
    breadcrumb: false,
    submenu: []
  },
  {
  key: 'classes',
  path: `${APP_PREFIX_PATH}/classes`,
  title: 'My Classes',
  icon: HddFilled,
  breadcrumb: false,
  submenu: []
  }
]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
