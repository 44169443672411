import Cookies from "js-cookie";
import {post, patch} from "../../../../services/ApiService";
import { setSessionCookie, getSessionCookie } from "../../../../context/sessionContext";

export const CreateAccount = async ( user ) =>{
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
    let response = await post("/users/v1/register", user, headers)
    if(response.hasOwnProperty('detail')){
        return "signup_error"
    }
    return "signup_success"
};

export const AuthenticateUser = async ( user )=>{
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    };
    try {
        let response = await post("/users/v1/login", user, headers);
        setSessionCookie(response);
        let { is_onboarded } = response
        if(is_onboarded){
            window.location.href = `/app/home`
        }
        else{
            window.location.href = `/onboarding`
        }
    }
    catch (error){
        const { message: errorMessage } = error
        if (errorMessage === 'User Account not verified'){
            return "not_verified"
        }
        return "auth_error"
    }

};

export const verifyEmailToken = async (email_token, user_id) => {
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
    return await patch(`/users/v1/email/verify`,{ email_token, user_id }, headers);
};

export const emailPasswordReset = async ( email ) =>{
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
    return await post(`/users/v1/email/password/reset`,{ email }, headers);
};

export const passwordReset = async ( email, token, password) =>{
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
    return await patch(`/users/v1/password/reset`,{ email, token, password }, headers);
};

export const sendVerificationEmail = async (email) =>{
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
    return await post(`/users/v1/email/new/verify_token`,{ email },headers);
};

export const SignOut = async () =>{
    Cookies.remove("session");
};

export const isAuthenticated = () =>{
    let cookieData = getSessionCookie()
    if(cookieData.token !== undefined){
        return true;
    }
    else{
        return false;
    }
};
