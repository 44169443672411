import React, {useEffect,useState} from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from './configs/AppConfig';
import { getSessionCookie,SessionContext } from './context/sessionContext';
import Views from './views';
import { Provider } from 'react-redux';
import store from './redux/store';
import './app.scss';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App(){
  const [session, setSession] = useState(getSessionCookie());
  useEffect(() => {
      setSession(getSessionCookie());
    },[]);

  return(
    <div className="App">
      <Provider store={store}>
        <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
          <SessionContext.Provider value={session}>
            <BrowserRouter>
              <Views/>
            </BrowserRouter>
          </SessionContext.Provider>
        </ThemeSwitcherProvider>
      </Provider>
    </div>
  );
}

export default App;
