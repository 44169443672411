import {patch} from "../../../services/ApiService";

export const SaveOnboardingData = async (payload,token) =>{
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
    }
    let response = await patch(`/users/v1/me/update`, payload, headers);
    return response;
    
};
