import React, {useContext} from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "../layouts/app-layout";
import AuthLayout from '../layouts/auth-layout';
import AppLocale from "../lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH, LANDING_PREFIX_PATH } from '../configs/AppConfig'
import useBodyClass from '../hooks/useBodyClass';
import LandingLayout from "../layouts/landing-layout";
import verify_email from "./auth-views/authentication/verify_email";
import ResetPassword from "./auth-views/authentication/forgot-password/ResetPassword";
import { isAuthenticated } from "./auth-views/authentication/services/AuthService";
import OnboardingPage from "./app-views/pages/onboarding";
import {QueryClient ,QueryClientProvider } from "react-query";
import { SessionContext } from "../context/sessionContext";
import { getUserProfile } from "../services/query/apiQuery";

function ProtectedRoutes({ children,...rest }) {
  return (
    <Route
    {...rest}
    render={({ location }) =>
    isAuthenticated() ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: AUTH_PREFIX_PATH,
            state: { from: location }
          }}
        />
      )
    }
  />
  );
};


const Views = (props) => {
  const cookie = useContext(SessionContext);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        queryFn:() =>{
          return getUserProfile(cookie.token)
        },
        retry: false
      },
    },
  });
  const { locale, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);
  return (
        <QueryClientProvider client={queryClient}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
              <Switch>
                <Route exact path="/">
                  <Redirect to={LANDING_PREFIX_PATH+"/main"} />
                </Route>
                <Route path={AUTH_PREFIX_PATH}>
                  <AuthLayout direction={direction} />
                </Route>
                <ProtectedRoutes path={APP_PREFIX_PATH}>
                  <AppLayout direction={direction} location={location}/>
                </ProtectedRoutes>
                <Route path={LANDING_PREFIX_PATH}>
                  <LandingLayout direction={direction} location={location}/>
                </Route>
                <ProtectedRoutes path="/onboarding">
                  <Route path="/" component={OnboardingPage}/>
                </ProtectedRoutes>
                <Route path="/email/:email_token/:first_name/:user_id" component={verify_email}/>
                <Route path="/reset/:token/password/:email" component={ResetPassword}/>
              </Switch>
            </ConfigProvider>
          </IntlProvider>
        </QueryClientProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } =  theme;
  const { token } = auth;
  return { locale, token, direction }
};

export default withRouter(connect(mapStateToProps)(Views));