import React from 'react';
import { Button, Row, Col, Layout } from "antd";
import { ladyCreator, startup, reachAudience, businessTools } from '../../../constants/Images';
import BootstrapNavbar from './NewNavbar';
import FooterNav from './Footer';
import {Mixpanel} from '../../../Mixpanel';

const Home = () => {
    Mixpanel.track("instructor page visit");

    const handleClick = () =>{
        Mixpanel.track("click instructor signup button");
        window.location.href = `/auth/register`
    }
	return (
        <>
           <BootstrapNavbar/>
                <Layout.Content>
                    <div className="marketing-section hero justify-content-between d-flex flex-column pb-md-4 pt-md-4">
                        <Row className="container" align="middle">
                            <Col className="marketing-section-title" xs={24} sm={24} md={14}>
                                <h1 className="mb-4 mt-4 display-4">Start and Grow<br/>your tutoring business</h1>
                                <p className="font-size-md mb-4">Join a growing network of tutors offering creative lessons in their communities.</p>
                                <Button size='large' shape='round' type="primary" onClick={handleClick}>Sign me up!</Button>
                            </Col>
                            <Col className="mb-4" xs={0} sm={0} md={10}>
                                <img className="img-fluid mt-md-0 mt-4" src={ladyCreator} aria-label="lady planning" width="650" height="650"/>
                            </Col>
                        </Row>
                    </div>
                    <div className="how-it-works">
                        <Row className="container pb-5" align="middle">
                            <Col className="how-it-works-heading" md={24}>
                                <h2>How It Works</h2>
                                <p className="mb-4">Create and verify your sqilet account in 3 easy steps to get started.</p>
                            </Col>
                            <Col md={24}>
                                <Row className="how-it-works-details">
                                    <Col xs={24} sm={24} md={8}>
                                        <svg width="100" height="100" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="24" cy="12" r="8" fill="#2F88FF" stroke="#333" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/><path d="M42 44C42 34.0589 33.9411 26 24 26C14.0589 26 6 34.0589 6 44" stroke="#333" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/><path d="M19 39H29" stroke="#333" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/><path d="M24 34V44" stroke="#333" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/></svg>
                                        <h4>Signup as instructor</h4>
                                        <p>
                                            Provide us with information about your business to help us better tailor solutions to make you and your business successful.
                                        </p>
                                    </Col>
                                    <Col xs={24} sm={24} md={8}>
                                        <svg width="100" height="100" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M43 33V19H5V41C5 42.1046 5.89543 43 7 43H24" stroke="#333" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/><path d="M5 10C5 8.89543 5.89543 8 7 8H41C42.1046 8 43 8.89543 43 10V19H5V10Z" stroke="#333" strokeWidth="1" strokeLinejoin="round"/><path d="M16 5V13" stroke="#333" strokeWidth="1" strokeLinecap="round"/><path d="M32 5V13" stroke="#333" strokeWidth="1" strokeLinecap="round"/><circle cx="30" cy="32" r="7" fill="#2F88FF" stroke="#333" strokeWidth="1"/><path d="M36 37L42 42" stroke="#333" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/></svg>
                                        <h4>Verify account</h4>
                                        <p>
                                            To maintain the trust and safety of the sqilet community we require everyone to undergo background verifications.
                                        </p>
                                    </Col>
                                    <Col xs={24} sm={24} md={8}>
                                        <svg width="100" height="100" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 13V9.00001C14 7.89544 14.8954 7.00001 16 7.00001H42C43.1046 7.00001 44 7.89544 44 9.00001V27C44 28.1046 43.1046 29 42 29H40" stroke="#333" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/><rect x="4" y="19" width="30" height="22" rx="2" fill="#2F88FF" stroke="#333" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/><path d="M4 28L34 28" stroke="#333" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/><path d="M34 23L34 35" stroke="#333" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/><path d="M4 23L4 35" stroke="#333" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/><path d="M11 34L19 34" stroke="#333" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/><path d="M25 34L27 34" stroke="#333" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/></svg>
                                        <h4>Create classes</h4>
                                        <p>
                                            Create your classes, curriculums, and schedules. Choose a price for your class and start earning.
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <div className="what-we-provide">
                        <Row className="container" align="middle">
                            <Col md={24} className="what-we-provide-heading">
                                <h2>Why partner with us?</h2>
                                <p className="mb-4" >
                                    We provide you with all of the tools, services, and reach needed to manage and run a successful tutoring business all in one place.
                                </p>
                            </Col>
                            <Col md={24}>
                                <Row className="first-details">
                                    <Col md={16} className="first-details-description">
                                        <h4>Create, manage, and schedule classes at your own pace</h4>
                                        <p>
                                            Create and manage your classes, curriculums, and schedules to target different offerings from online to in-person.
                                            Manage pricing and payments, cancellations, and student engagements all on a single dashboard.
                                        </p>
                                    </Col>
                                    <Col md={8} xs={0} sm={0} className="first-details-image">
                                        <img className="img-fluid mt-md-0 mt-4" src={startup} aria-label="startup life" width="450" height="500"/>
                                    </Col>
                                </Row>
                                {/*  */}
                                <Row className="second-details">
                                    <Col md={8} xs={0} sm={0} className="second-details-image">
                                        <img className="img-fluid" src={reachAudience} aria-label="two people messaging" width="450" height="500"/>
                                    </Col>
                                    <Col md={16} className="second-details-description">
                                        <h4>Reach our network of motivated students ready to learn</h4>
                                        <p>
                                            Reach our verified list of creatives yearning to learning, and grow bookings for your classes.
                                            Create and publish your classes, we do the rest by getting it to the right creatives for bookings.
                                        </p>
                                    </Col>
                                </Row>
                                {/*  */}
                                <Row className="third-details">
                                    <Col md={16} className="third-details-description">
                                        <h4>Tools and services to help grow your business</h4>
                                        <p>
                                            Want to know how your classes are doing?, we provide you with all the engagement information on your classes, to help you make decisions.
                                            We also provide you with a suite of analytical tools that help your business.
                                        </p>
                                    </Col>
                                    <Col md={8} xs={0} sm={0} className="third-details-image">
                                        <img className="img-fluid" src={businessTools} aria-label="planning and charts" width="450" height="500"/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Layout.Content>
            <FooterNav/>
            </>
	)
}
export default Home;
