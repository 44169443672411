import {patch,post,get, upload} from "../../../services/ApiService";

export const UpdateUser = async (params,token) =>{
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
    }
    return await patch('/users/v1/me/update', params, headers)
};

export const GetUser = async (token)=>{
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
    }
    return await get('/users/v1/me', headers);
};

export const GetUserProgress = async (token) =>{
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
    }
    return await get('/users/v1/progress',headers)
};

export const UpdateAddress = async ( params,token ) =>{
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
    }
    return await post('/users/v1/me/address', params, headers )
};

export const UploadFiles = async (files, token)=>{
    const headers = {
        Authorization: `Bearer ${token}`
    }
    return await upload('/users/v1/me/upload', files, headers)
};
