import React from "react";
import { Menu, Dropdown } from "antd";
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import Icon from '../../components/util-components/Icon';
import { SignOut } from '../../views/auth-views/authentication/services/AuthService';
import { Link, useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import UserAvatar from "../shared-components/UserAvatar";

const menuItem = [
    {
		title: "Profile",
		icon: UserOutlined ,
		path: "/app/profile"
    }
]

const NavProfile = () => {
  const history = useHistory();
	const { data, status } = useQuery("user");

  function handleSignout(){
    SignOut().then(
      ()=>history.push("/landing/main")
      ).catch(()=>console.error("Failed to signout"))
  };

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <div className="pl-3">
            {status==="success"?<h4 className="mb-0">{data.first_name+" "+data.last_name}</h4>:<></>}
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <Link to={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </Link>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={() => handleSignout()}>
            <span>
              <LogoutOutlined className="mr-3"/>
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key={'user-avatar'}>
          <UserAvatar/>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};
export default NavProfile;
