import React from 'react';
import {Select} from 'antd';

const SelectComponent = ({ dataCy, children, placeholder, selected, disabled, value }) => {

    function onChange(value) {
        selected(value)
    }

    function onSearch(value) {
        selected(value)
    }
    return (
        <Select
            disabled = { disabled }
            data-cy={dataCy}
            showSearch
            style={{
            width: 200
        }}
        placeholder={placeholder}
        onChange={onChange}
        onSearch={onSearch}
        value = { value }
        >
            {
                children
            }
        </Select>
    );
};

export default SelectComponent;