import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from '../../components/shared-components/Loading';
import { APP_PREFIX_PATH } from '../../configs/AppConfig';

const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
        <Route path={`${APP_PREFIX_PATH}/profile`} component={lazy(() => import(`./pages/dashboard`))} />
        <Route path={`${APP_PREFIX_PATH}/onboarding`} component={lazy(() => import(`./pages/onboarding`))} />
        <Route path={`${APP_PREFIX_PATH}/user`} component={lazy(() => import(`./pages/user`))} />
        <Route path={`${APP_PREFIX_PATH}/new/class`} component={lazy(() => import(`./pages/courses`))} />
        <Route path={`${APP_PREFIX_PATH}/edit/class/:id`} component={lazy(() => import(`./pages/courses`))} />
        <Route path={`${APP_PREFIX_PATH}/class/:id/details`} component={lazy(() => import(`./pages/courses/CourseDetailRead`))} />
        <Route path={`${APP_PREFIX_PATH}/schedules`} component={lazy(() => import(`./pages/schedule/Schedules`))} />
        <Route path={`${APP_PREFIX_PATH}/billing`} component={lazy(() => import(`./pages/payment/BillingDetails`))} />
        <Route path={`${APP_PREFIX_PATH}/classes`} component={lazy(() => import(`./pages/courses/List`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

export default AppViews;