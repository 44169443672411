import {apiDelete,post,get,patch} from "../../../services/ApiService";


export const CreateCourse = async ( params, token )=>{
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
    }
    try {
        return await post('/courses/v1/create', params, headers)
    } catch (error) {
        throw new Error(error)
    }
};

export const  GetCourses = async (status, token)=>{
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
    }
    return await get(`/courses/v1/my/courses?status=${status}`, headers)
};

export const GetCourseInfo = async (id, token)=>{
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
    }
    return await get(`/courses/v1/${id}`, headers)
};

export const UpdateCourse = async (id, params, token) =>{
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
    }
    return await patch(`/courses/v1/${id}/edit`, params, headers)
};

export const DeleteCourse = async (id, token) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
    }
    return await apiDelete(`/courses/v1/${id}/delete`, headers)
};
