import {get,post} from "../../../services/ApiService";

export const CreateBillingAccount = async (account, token) =>{
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
    }
    return await post('/billing/v1/accounts', account, headers)
};

export const addPaymentTypes = async (paymentTypes, token) =>{
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
    }
    return await post('/billing/v1/add_bank_card', paymentTypes, headers)
};

export const getAccount = async (token) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
    }
    return await get('/billing/v1/account', headers)
};

export const getAccountBalance = async (token) =>{
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
    }
    return await get('/billing/v1/balances', headers)
};
