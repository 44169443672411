import React from 'react';
import { Navbar} from 'react-bootstrap';
import {sqiletLogo} from "../../../constants/Images";
import "./navbar.scss";

const BootstrapNavbar = () =>{
    return(
        <Navbar collapseOnSelect className="container-fluid sqilet-header-navbar" fixed="top" expand="md">
            <div className="container-fluid">
                <div className="navbar-brand">
                    <a href="/landing/main">
                        <img src={sqiletLogo} alt="sqilet logo" width={32}/>
                    </a>
                </div>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <ul className="nav nav-fill nav-pills header-nav">
                        <li className="nav-item">
                            <a className="nav-link" href="/landing/privacy">Privacy policy</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/landing/terms">Terms of service</a>
                        </li>    
                        <li className="nav-item">
                            <a className="nav-link" href="/auth/register">Instructor signup</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/auth/login">Sign-in</a>
                        </li>
                    </ul>
                </Navbar.Collapse>
            </div>
        </Navbar>
    );
}
export default BootstrapNavbar;