import React, { useState, useRef } from 'react';
import { Tag, Input, Tooltip } from 'antd';
import { PlusOutlined, WarningFilled } from '@ant-design/icons';

const CustomCategories = ({ interests = [], saveInterest = () => {} }) => {
    const inputRef = useRef(null)

    const [input, setInput] = useState({
        visible: false,
        value: '',
        alreadyExists: false
    })

    const [tags, setTags] = useState([])

    const handleInputChange = (e) => {
        const { value } =  e.target

        const validateExists = (collection) => {
            return collection.findIndex(item => item.toLowerCase() === value.toLowerCase()) !== -1
        }

        setInput({
            ...input,
            value,
            alreadyExists: validateExists(tags) || validateExists(interests)
        })
    }

    const handleClose = (tagRemoved) => {
        const idx = tags.findIndex(tag => tag === tagRemoved)
        tags.splice(idx, 1)

        setTags(tags)
        saveInterest(tagRemoved)
    }

    const handleInputConfirm = () => {
        const { value, alreadyExists } = input

        if (value === '' || alreadyExists) {
            setInput({
                visible: false,
                alreadyExists: false,
                value: ''
            })

            return
        }

        tags.push(value)

        setTags(tags)
        setInput({
            value: '',
            visible: false
        })

        saveInterest(value)
    }

    const showInput = () => {
        setInput({
            ...input,
            visible: true
        })
    }

    return (
        <>
            {tags.map((tag) => {
                const isLongTag = tag.length > 20
                console.log({isLongTag, tag})
                const tagElem = (
                <Tag className="ml-1 mt-2" style={ { padding: 6, borderRadius: 9, fontSize: 14, minWidth: 85 } } color="#3e79f7" key={tag} closable={true} onClose={() => handleClose(tag)}>
                    {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                </Tag>
                )
                return isLongTag ? (
                <Tooltip title={tag} key={tag}>
                    {tagElem}
                </Tooltip>
                ) : (
                tagElem
                )
            })}
            {input.visible && (
                <>
                    <Input
                    className="ml-2 mt-2"
                    ref={inputRef}
                    type="text"
                    size="small"
                    style={{ width: 119 }}
                    value={input.value}
                    autoFocus={true}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                    />
                    <br />
                    {
                        input.alreadyExists &&
                        <Tag className="mt-4" color="red">
                            <WarningFilled className="mr-1" />
                            Category already exists
                        </Tag>
                    }
                </>
            )}
            {!input.visible && (
                <Tag style={ { padding: 8, borderRadius: 9, fontSize: 14 } } className="ml-2 mt-2 site-tag-plus" onClick={showInput}>
                <PlusOutlined /> Add Category
                </Tag>
            )}
        </>
    )
}

export default CustomCategories
