import React,{ useState } from 'react';
import { motion } from 'framer-motion';
import { Alert } from 'antd';
import PropTypes from 'prop-types';

//hook to controll the component state
export const AlertHook = () =>{
    const [ alertProps, setProps ] = useState( AlertMessage.defaultProps )

    return [ alertProps, setProps ]
}
const AlertMessage = ( {...props} ) => {
    const {
        alertType,
        message,
        show
    } = props
    return (
        <motion.div 
            initial={{ opacity: 0, marginBottom: 0 }} 
            animate={{ 
                opacity: show ? 1 : 0,
                marginBottom: show ? 20 : 0
            }}> 
            <Alert type={ alertType } showIcon message={ message }></Alert>
        </motion.div>
    );
};

//using propTypes and default props to specify the type of attributes that this component will allow
AlertMessage.propTypes = {
    alertType: PropTypes.string,
    message: PropTypes.string,
    show: PropTypes.bool
}

AlertMessage.defaultProps = {
    alertType : "info", //[ success, info, warning, error ]
    message : "",
    show : false,
}

export default AlertMessage;