import React from 'react';
import { Navbar} from 'react-bootstrap';
import "./navbar.scss";

export const FooterNav = () => {
    return(
        <div className="sqilet-footer-navbar">
            <Navbar className="container" expand="lg">
                    <div className="row">
                        <div className="col footer-pad">
                            <h4>About Us</h4>
                            <ul className="list-unstyled">
                                <p>
                                    sqilet is a community for creatives to discover classes taught by other experienced creatives in-person, on-line, or both.
                                </p>
                            </ul>
                        </div>
                        <div className="col footer-pad">
                            <h4>Contact Us</h4>
                            <ul className="list-unstyled">
                                <p>3940 Laurel Canyon Blvd #1253, Studio City, CA, 91604</p>
                                <p>support@sqilet.com</p>
                            </ul>
                        </div>
                        <div className="col footer-pad">
                            <h4>Terms and Policies</h4>
                            <ul className="list-unstyled">
                                <p><a href="/landing/privacy">Privacy policy</a></p>
                                <p><a href="/landing/terms">Terms of service</a></p>
                                <p><a href="https://www.freepik.com/vectors/design">Design vector created by stories - www.freepik.com</a></p>
                            </ul>
                        </div>
                    </div>
            </Navbar>
        </div>
    );
}
export default FooterNav;
