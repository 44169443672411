import { useContext, useEffect, useState } from "react";
import { Button, Row, Col } from "antd";
import * as dayjs from 'dayjs';
import SelectComponent from "../../../../components/shared-components/Forms/SelectComponent";
import {OnboardingState} from "./context";
import { months, getDaysOfMoth, getYears } from "../../../../components/util-components/DateUtils/functions";

const DateOfBirth = () => {
    const { onboardingInfo, saveDateBirth, setBirthDay, forward, setOnboardingInfo } = useContext(OnboardingState);
    const [ next, setNext  ] = useState(false);

    useEffect(()=>{
        const { month, day, year } = onboardingInfo;
        if(month > 0 && day > 0 && year > 0){
            saveDateBirth( `${year}-${month}-${day}` )
        }
    },[onboardingInfo.month,onboardingInfo.day,onboardingInfo.year])

    useEffect(()=>{
        if(dayjs(onboardingInfo.date_of_birth, 'YYYY-MM-DD', true).isValid()){
            let useryears = dayjs().diff(onboardingInfo.date_of_birth,'years',false);
            if( useryears>=18 ){
                setOnboardingInfo({...onboardingInfo, step:1});
                setNext(true);
            } else{
                setNext(false);
            }
        }
    },[onboardingInfo.date_of_birth]);

    return(
        <Row align="middle">
            <Col align="middle" justify="center" md={24}>
            {/* TODO: add the user name after -Hello- is needed to do a request to get that value*/}
            <h1>Hello</h1>
                <span>Must be 18 years or older to continue with account setup</span>
                <Row justify="center">
                    <Col align="middle" >
                        <div className="form-group mt-5 ml-2">
                            <SelectComponent dataCy="dob-month-select" placeholder="Month" selected={ (value)=>setBirthDay("month", value)  }>
                                {
                                    months.map(item => ( 
                                        <option data-cy="dob-month-option" key={item} value={item}>{item}</option>
                                    ))
                                }
                            </SelectComponent>
                        </div>
                    </Col>
                    <Col align="middle" >
                        <div className="form-group mt-5 ml-2">
                            <SelectComponent disabled={ onboardingInfo.month === 0 } dataCy="dob-day-select" placeholder="Day" selected={ (value)=>setBirthDay("day", value)  } notFoundContent="Select a month first">
                                {
                                    onboardingInfo.month > 0 && getDaysOfMoth(onboardingInfo.month).map(item => ( 
                                        <option data-cy="dob-day-option" key={item} value={item}>{item}</option>
                                    ))
                                }
                            </SelectComponent>
                        </div>
                    </Col>
                    <Col align="middle" >
                        <div className="form-group mt-5 ml-2">
                            <SelectComponent dataCy="dob-year-select" placeholder="Year" selected={ (value)=>setBirthDay("year", value)  }>
                                {
                                    getYears().map(item => ( 
                                        <option key={item} value={item}>{item}</option>
                                    ))
                                }
                            </SelectComponent>
                        </div>
                    </Col>
                </Row>
                <section className="form-group mt-5">
                    <Button shape="round" type="primary" disabled={ !next } onClick={() => forward(1) }>Next</Button>
                </section>
            </Col>
        </Row>
    );
};
export default DateOfBirth;
