import React,{ useState,useEffect } from 'react';
import { Input, Row, Col, Alert, Button } from "antd";
import  { useParams } from 'react-router-dom'

import { verifyEmailToken, sendVerificationEmail } from '../services/AuthService';

const EmailVerifyPage = () => {
    const { first_name, email_token, user_id } = useParams()
    const [ response_type, setResponse ] = useState(null)
    const [ email, setEmail ] = useState("")
    const [ loading, setLoading ] = useState(false)
    const [ email_response, handleResponse ] = useState({ success : false, error : false})

    useEffect(()=>{
        let response = verifyEmailToken(email_token,user_id)
        if(response.hasOwnProperty('detail')){
            setResponse(response.detail)
        }
    },[email_token, user_id])

    const reSendEmail = async () =>{
        if(email !== ""){
            let response = await sendVerificationEmail( email )
            if(!response.hasOwnProperty('detail')){
                handleResponse({ ...email_response , success : true })
                return
            }else{
                handleResponse({ ...email_response , error : true })
                setLoading(false)
            }
        }
    }

    return (
        <div style={{height: "100vh"}}>
			<Row justify="center" className="align-items-stretch h-100">
				<Col xs={14}>
					<div className="d-flex flex-column justify-content-center h-100 px-4">
						<Row justify="center">
							<Col xs={24}  align="middle">
								{
                                    response_type !== null ?
                                        <>
                                            <h1>Looks like your link has expired, please type your email and we will send to you a new one.</h1>
                                            <Input.Search disabled={email_response.success} placeholder="Type your email" enterButton="Send" size="large" onChange={ (e) => setEmail(e.target.value) } loading={loading} onSearch={() => [reSendEmail(), setLoading(true)] } style={{ marginBottom : 15 }}/>
                                            { email_response.success && <Alert message="The link was sent successfully" type="success" />}
                                            { email_response.error && <Alert message="Something when wrong with your email, try again or try to sign up." type="info" />}
                                        </>
                                    :
                                    <>
                                        <h1>Hello {first_name}</h1>
                                        <h1>Your user account is now verified</h1>
                                        <Button href={ `${window.location.origin}/auth/login` } className="mt-4" size="large" type="primary">
                                            Signin To Get Started
                                        </Button>
                                    </>
                                }
							</Col>
						</Row>
					</div>
				</Col>
			</Row>
		</div>
    );
};

export default EmailVerifyPage;
